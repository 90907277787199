.input {
    &-field {
        height: 50px;
        border: 0;
        padding: $spacingUnit 20px;
        font-size: 16px;
        width: 100%;

        @include placeholderColor($color10);

        &--label {
            display: block;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 5px;
        }
    }
}

.input-checkbox--container {
    display: block;
    position: relative;
    padding-left: $spacingUnit * 2;
    margin-bottom: $spacingUnit;
    cursor: pointer;
    user-select: none;
    line-height: 20px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .input-checkbox--checkmark {
        border: 1px solid #666;
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        transition: background-color $transition ease;
    }

    .important-icon {
        width: 20px;
        vertical-align: middle;
    }
  }

  .input-checkbox--container input:checked ~ .input-checkbox--checkmark {
    background-color: $color5;
  }

  .input-checkbox--checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .input-checkbox--container input:checked ~ .input-checkbox--checkmark:after {
    display: block;
  }