.details {
    margin-top: 80px;
    margin-bottom: 80px;

    h1,
    h2 {
        font-size: 30px;
    }

    p {
        font-size: $button;
    }

    &-img {
       margin-top: $spacingUnit * 2; 
       margin-bottom: $spacingUnit * 4; 
       display: flex;
       justify-content: flex-start;
       flex-wrap: wrap;

       .profile-img {
           flex-shrink: 0;
           flex-grow: 1;
           margin-right: 0;
           flex-basis: calc(20% - 6px);
           max-width: 65px;
           margin-right: 6px;
           margin-bottom: 6px;

           &--selected {
               border-radius: 50%;
               border: 5px solid #be9847;
           }
       }

       img {
           width: 100%;
           height: 100%;
           display: block;
       }
    }

    &-confirm {
        label {
            font-size: 16px;

            a {
                text-decoration: none;
                color: $color5;
                transition: color $transition ease;

                &:hover {
                    color: lighten($color5, 10%);
                }
            }
        }

        &--tooltip {
            position: absolute;
            top: -60px;
            right: -52px;
            font-size: 10px;
            width: 120px;
            background-color: $color10;
            display: block;
            line-height: 1.2;
            text-align: center;
            margin: 0;
            padding: 5px;
            border-radius: 5px;
            color: #fff;
            box-shadow: $boxShadow;
            visibility: hidden;
            opacity: 0;
            transition: $transition ease;

            &:after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: $color10 transparent transparent transparent;
            }
        }
    }
}

.tooltip {
    &-container {
        position: relative;
        display: inline-block;

        &:hover {
            .details-confirm--tooltip {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}