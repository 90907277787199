.overlay {
    padding: $spacingUnit;
    position: fixed;
    overflow-y: scroll;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.85);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity $transition ease;
    visibility: visible;
    opacity: 1;

    &--inactive{
        opacity: 0;
        visibility: hidden;
    }

    // transition: 1s;

    &-container {
        // width: calc(100% - (#{$spacingUnit} * 4));
        width: calc(100% - #{$spacingUnit});
        max-width: 1170px;
        max-height: 100vh;
        position: relative;
        // padding: 100px 0;

        @media(max-width: 767px) {
            padding-top: clamp(60px, 20%, 100px);
        }
    }

    &-close {
        // position: absolute;
        padding-right: 20px;
        // top: -50px;
        // top: 0;
        // right: 0;
        display: block;
        margin-left: auto;
        margin-right: 0;
        background-color: transparent;
        color: #fff;
        border: 0;
        text-transform: uppercase;
        font-family: $serif;
        font-weight: 700;
        font-size: 15px;
        height: 50px;
        line-height: 50px;
        background-image: url(../svg/close.svg);
        background-size: 14px;
        background-position: right center;
        background-repeat: no-repeat;
        transition: opacity $transition ease;

        &:hover {
            cursor: pointer;
            opacity: 0.5;
        }
    }

    &--webcamview {
        iframe {
            border-radius: $borderRadius;
        }
    }

    &-pop {
        padding: $spacingUnit * 2;
        border-radius: $borderRadius;
        color: $color10;

        &--video {
            width: 100%;
            max-width: 825px;
            margin-left: auto;
            margin-right: auto;

            @include aspect-ratio(16,9);

            iframe {
                width: 100%!important;
                height: 100%!important;
            }
        }

        &--switch {
            background-color: $color4;
            color: #fff;
            text-align: center;
            padding: 0 0 150px 0;

            .card-scroller {
                justify-content: center;

                .card-plot--item:nth-child(1) {
                    margin-left: 15px;
                }
            }
        }

        &--webcam {
            width: 100%;
            background-color: #fff;
            text-align: center;
            border-radius: $borderRadius!important;
            padding: 50px;
            margin-bottom: 60px;

            h2 {
                font-size: 30px;
            }

            .card-live {
                p {
                    margin: 0;
                    text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
                }

                @media(min-width: 768px) {
                    flex-direction: row;
                    align-items: center;

                    .card-live--indicator {
                        margin-bottom: 0;
                    }
                }
            }

            @media(max-width: 767px) {
                padding: 25px 10px;

                .card-weather--reading {
                    margin-bottom: 10px;
                }
            }

            &Feed {
                height: auto;
                width: 100%;
                // max-width: 830px;
                // margin-right: auto;
                // margin-left: auto;
                background-image: url(../images/webcam-placeholder.png);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                display: block;
            }

            article#cardWebcam.card {
                max-width: 100%;
                padding-bottom: 33%;
                margin: 0;

                &::after{
                    display: none;
                }
            }

            @include desktop {
                padding: $spacingUnit * 2;
            }
        }
        &--plot {
            padding: $spacingUnit;
            padding-left: calc((#{$spacingUnit * 2}) + 30px);
            color: $color4;
            background-color: #fff;
            background-image: url(../svg/check-circle.svg);
            background-size: 30px;
            background-position: $spacingUnit center;
            background-repeat: no-repeat;
            width: max-content;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;

            @include ipad {
                padding: $spacingUnit * 2;
                padding-left: calc((#{$spacingUnit * 4}) + 40px);
                background-size: 40px;
                background-position: $spacingUnit * 2 center;
            }

            h2 {
                margin: 0;
                font-size: 20px;

                @include ipad {
                    font-size: 24px;
                }
            }
        }

        &--viewplot {
            background-color: #fff;
            border-radius: $borderRadius;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            // overflow-y: scroll;

            @include tablet {
                flex-wrap: nowrap;
            }
        }

        &--logvisit {
            background-color: #fff;
            text-align: center;
            border-radius: $borderRadius;
            padding: $spacingUnit * 2;

            h2 {
                max-width: 100%;
            }
        }
    }

    &-switch {
        &--content {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            padding: $spacingUnit * 2;

            h2 {
                font-size: 30px;
            }

            p {
                font-size: 16px;
            }

        }
    }

    &-webcam {
        &--live,
        &--favourite {
            max-width: 830px;
            margin-right: auto;
            margin-left: auto;
        }

        &--live {
            margin-bottom: $spacingUnit * 4;
        }

        &--favourite {
            // margin-bottom: 120px;

            .arrow-path {
                fill: #fff;
            }

            .slider-control {
                transition: opacity $transition ease;

                &:hover {
                    opacity: 0.5;
                }
            }

            .slider-control--prev {
                left: 30px;
            }

            .slider-control--next {
                right: 30px;
            }
        }

        &--timelapse {
            height: 70px;
            background-color: $color3;
            transition: background-color $transition ease;

            &:hover {
                background-color: darken($color3, 10%);

                button {
                    cursor: pointer;
                }
            }

            button {
                background-color: transparent;
                border: 0;
                height: 70px;
                color: #fff;
                font-family: $sansSerif;
                font-weight: 500;
                font-size: 16px;
                width: 100%;

                span {
                    display: block;
                }

                .play-path {
                    fill: #fff;
                }
            }
        }
    }



    &-viewplot {

        &--content {
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            @include ipad {
                &::after {
                    content: '';
                    height: calc(100% - (#{$spacingUnit} * 4));
                    position: absolute;
                    width: 1px;
                    display: block;
                    background-color: rgba(0,0,0,0.1);
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    top: $spacingUnit * 2;
                }
                &.overlay-viewplot--content--nobar::after {
                    display: none;
                }
            }
        }

        &--img {
            // width: 370px;
            flex-shrink: 0;
            flex-grow: 0;
            width: 100%;

            @include aspect-ratio(4,3);

            @include ipad {
                @include aspect-ratio(16, 9);
            }

            @include tablet{
                width: 30%;
                max-width: 275px;
            }

            @include desktop {
                max-width: 370px;
            }

            .ratio-content {
                margin: 0;
                background-image: url(../images/plot-overlay-glencoe.jpg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            &-lochaber{
                .ratio-content {
                    margin: 0;
                    background-image: url(../images/plot-overlay-lochaber.jpg);
                }
            }
            &-kilnaish{
                .ratio-content {
                    margin: 0;
                    background-image: url(../images/plot-overlay-kilnaish.jpg);
                }
            }
        }
        &--data {
            flex-grow: 1;
        }
        &--name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $spacingUnit;

            h2 {
                margin: 0;
            }

            @include desktop {
                padding: $spacingUnit * 2;
            }
        }

        &--general,
        &--downloads {
            background-color: $color2;
            padding: $spacingUnit;
            width: 100%;

            @include ipad {
                width: 50%;
                flex-basis: 50%;
                flex-grow: 0;
                flex-shrink: 0;
            }

            @include desktop {
                padding: $spacingUnit * 2;
            }

            h2 {
                font-family: $sansSerif;
                font-weight: 600;
                margin-top: 0;
            }
        }

        &--general {
            ul {
                list-style: none;
                padding: 0 0 0 0;
                margin: 0 0 $spacingUnit * 2 0;

                &:last-child {
                    margin-bottom: 0;
                }

                li {
                    a {
                        color: $color5;
                        text-decoration: none;
                    }
                    margin-bottom: 10px;

                    span {
                        font-weight: 700;
                    }
                }
            }

            table{
                width: 100%;
                h4{
                    font-size: 1.2rem;
                }
                tbody{
                    td{
                        padding: 1rem;
                        // break order dets & billing and delivery onto single rows at mob
                        @media screen and (max-width:768px){
                            &[valign="top"] {
                                display: block;
                            }
                        }
                    }

                }
            }
        }

        &--downloads {
            text-align: center;
            padding-bottom: 120px;

            &Preview {
                width: 100%;
                height: auto;
                margin-bottom: $spacingUnit;

                @include desktop {
                    margin-bottom: $spacingUnit * 2;
                }
            }

            .btn {
                display: inline-block;
                margin-left: auto;
                margin-right: auto;
                &.hidden{
                    display: none;
                }
            }
        }
    }

    &--logvisit {
        .overlay-container {
            max-width: 430px;
        }
    }
}