.help {

    &__video {
        margin-top: 30px;
    }

    .faqs {
        padding: 40px 0;
    
        &__container {
            max-width: 1170px;
            margin-left: auto;
            margin-right: auto;
    
            >*:first-child {
                margin-top: 0;
            }
    
            >*:last-child {
                margin-bottom: 0;
            }
        }
    
        &__title {
            &--container {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
    
                @include desktop {
                    flex-wrap: nowrap;
                }
    
                h2 {
                    // margin: 0 0 $spacing 0;
                    // color: $charcoal;
                    width: 100%;

    
                    @include desktop {
                        margin-bottom: 0;
                    }
                }
            }
        }
    

        &__question {
            // min-height: $inputHeight;
            border-radius: $borderRadius;
            // background-color: rgb(231,231,231);
            background-color: $color7;
            font-size: 18px;
            font-weight: 600;
            // font-family: $sansPoppins;
            // color: $charcoal;
            display: flex;
            align-items: center;
            padding: $spacingUnit $spacingUnit $spacingUnit 40px;
            transition: background-color $transition ease, color $transition ease;
            margin-bottom: 30px;
            position: relative;
    
    
            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                background-color: $color5;
            }
            
            &::before {
                height: 3px;
                width: 15px;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
                transform-origin: center;
                transition: $transition ease;
            }
    
            &::after {
                height: 15px;
                width: 3px;
                left: 21px;
                top: 50%;
                transform: translateY(-50%);
                transition: $transition ease;
            }
    
            &:hover {
                cursor: pointer;
                background-color: $color8;
            }
    
            &.faqs__question--open {
                background-color: $color5;
                color: #fff;
    
                &::before,
                &::after {
                    background-color: #fff;
                }
    
                &::after {
                    opacity: 0;
                    height: 0;
                }
            }
        }
    
        &__answer {
            overflow:hidden;
            transition: height $transition ease, padding-top $transition ease, padding-bottom $transition ease;
            height: auto;
            font-size: $button;
    
            >*:nth-child(1) {
                margin-top: 0px;
            }
    
            >*:last-child {
                margin-bottom: 30px;
            }
    
            &[data-collapsed="true"] {
                height: 0px;
            }
        }
    }
}