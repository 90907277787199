.orders {
    margin-top: 20px;
    margin-bottom: 80px;
    position: relative;

    h1 {
        font-size: 30px;
    }

    &-table {
        display: block;
        overflow-x: scroll;
        width: 100%;
        font-size: 16px;

        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100px;
            background: linear-gradient(270deg,rgba(245,245,245,1) 0,rgba(245,245,245,0) 100%);
        }

        thead {
            font-weight: 700;
            text-align: left;

            th {
                padding: 10px 0;
                min-width: 120px;
            }
        }

        tbody {
            td {
                padding: 10px 0;
                min-width: 120px;

                a {
                    color: $color5;
                    font-weight: 700;
                    text-decoration: none;
                    transition: color $transition ease;

                    &:hover {
                        color: lighten($color5, 10%);
                    }
                }
            }
        }
    }
}