.footer {
    color: #fff;
    text-align: center;
    padding: $spacingUnit * 2 $spacingUnit;

    &-copyright {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
    }
}