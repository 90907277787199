.header {
    display: flex;
    box-sizing: border-box;
    padding: $spacingUnit;
    height: 73px;
    justify-content: center;
    max-width: $maxContentWidth;
    margin: 0 auto;

    &-login {
        height: auto;
    }

    &-burger {
        margin-right: auto;
        position: relative;
        padding: 0;
        height: 40px;
        width: 40px;
        border: none;
        background-color: transparent;

        span {
            display: block;
            width: 100%;
            height: 2px;
            background-color: #fff;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                position: absolute;
                width: 100%;
            }

            &:nth-child(1) {
                transform-origin: left;
                top: 10px;
                transition: left $transitionFast ease, transform $transitionFast ease, width $transitionFast ease, top $transitionFast ease;
            }

            &:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);
                transition: width $transitionFast;
            }

            &:nth-child(3) {
                bottom: 10px;
                transform-origin: left;
                transition: transform $transitionFast ease, width $transitionFast ease, bottom $transitionFast ease;
            }
        }

        &:focus {
            outline: none;
        }
    }

    &-logo {

        &--mobile {
            align-self: center;
            width: 140px;
            height: 100%;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                display: block;
            }
        }

        &--desktop {
            display: none;
        }
    }

    &-profile {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #000;
        margin-left: auto;

        img {
            width: 100%;
            height: auto;
        }
    }

    &-return {
        display: none;
    }

    @include desktop {
        position: relative;
        height: 135px;
        padding: $spacingUnit * 2;
        justify-content: flex-end;
        align-items: flex-end;

        &-login {
            height: auto;
        }

        &-burger,
        &-profile {
            display: none;
        }

        &-return {
            display: flex;
            color: #fff;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 15px;
            font-weight: 700;
            font-family: $serif;
            opacity: 1;
            transition: opacity $transition ease;


            svg {
                width: 14px;
                transition: transform $transition ease;
            }

            &:hover {
                opacity: 0.65;

                svg {
                    transform: translateX(6px);
                }
            }
        }

        &-logo {

            &--mobile {
                display: none;
            }

            &--desktop {
                position: absolute;
                left: $spacingUnit * 2;
                top: $spacingUnit * 4;
                align-self: flex-end;
                width: 350px;
                height: auto;
                display: block;

                img {
                    width: 100%;
                    display: block;
                }

                &Center {
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    top: auto;
                    position: relative;
                }
            }
        }
    }
}

.menu-open {
    .header-burger {
        span {
            &:nth-child(1) {
                width: 14px;
                top: 47%;
                left: 2px;
                transform: rotate(-45deg) translateY(-50%);
            }

            &:nth-child(2) {
                width: 25px;
            }

            &:nth-child(3) {
                width: 14px;
                bottom: 46%;
                transform: rotate(45deg) translateY(-50%);
            }
        }
    }
}