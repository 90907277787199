.btn {
    text-decoration: none;
    padding: 10px $spacingUnit;
    color: #fff;
    background-color: $color5;;
    border: 2px solid $color5;
    border-radius: $borderRadius;
    font-size: $button;
    font-weight: 500;
    display: inline-block;
    transition: background-color $transition ease, border-color $transition ease, color $transition ease;

    &:focus,
    &:hover {
        border-color: lighten($color5, 10%);
        background-color: lighten($color5, 10%);
    }

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    &-outline {
        &--white {
            border-color: #fff;
            background-color: transparent;

            &:hover,
            &:focus {
                border-color: white;
                background-color: white;
                color: $color10;
            }
        }
    }

    &-secondary {
        background-color: $color1;
        border-color: $color1;

        &:hover,
        &:focus {
            border-color: lighten($color1, 10%);
            background-color: lighten($color1, 10%);
        }
    }

    &-gold {
        background-color: $color3;
        border-color: $color3;

        &:hover,
        &:focus {
            border-color: darken($color3, 10%);
            background-color: darken($color3, 10%);
        }
    }

    &-grey {
        background-color: $color8;
        border-color: $color8;

        &:hover,
        &:focus {
            border-color: darken($color8, 10%);
            background-color: darken($color8, 10%);
        }
    }

    &-signin {
        padding-top: 15px;
        padding-bottom: 15px;
        min-height: 50px;
        text-align: center;
        font-size: 18px;
        margin-top: 20px;

        &--facebook,
        &--google {
            background-size: 20px;
            background-repeat: no-repeat;
            background-position: 20px center;
            padding-left: 55px;
        }

        &--facebook {
            margin-top: 0;
            background-image: url(../svg/facebook-f.svg);
            background-color: rgb(57,86,158);
            border-color: rgb(57,86,158);

            &:hover {
                background-color: darken(rgb(57,86,158), 10%);
                border-color: darken(rgb(57,86,158), 10%);
            }
        }

        &--google {
            background-image: url(../svg/google-g.svg);
            background-color: rgb(241,57,44);
            border-color: rgb(241,57,44);

            &:hover {
                background-color: darken(rgb(241,57,44), 10%);
                border-color: darken(rgb(241,57,44), 10%);
            }
        }
    }
}