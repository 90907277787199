$serif: 'EB Garamond', 'Didot', 'Georgia', 'Times New Roman', serif;
$sansSerif: proxima-nova, 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

$cardTitle: 30px;

$button: 16px;

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $serif;
    font-weight: 600;
}

p {
    a {
        color: $color5;

        &:hover,
        &:active {
            text-decoration: none;
        }
    }
}