.slider {
  position: relative;
  width: 100%;

  &-wrapper {
    overflow: hidden;
    position: relative;
    z-index: 1;
    width: 100%;
  }

  &-slides {
    display: flex;
    position: relative;
    top: 0;
    width: 10000px;

    &.shifting {
      transition: left .2s ease-out;
    }
  }

  &-slide {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-color: #fff;
  }

  &-control {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    margin-top: -20px;
    z-index: 2;

    &--prev,
    &--next {
      background-size: 22px;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
      }

      &:active {
        transform: scale(.8);
      }
    }

    &--prev {
      left: -20px;
      padding-left: 10px;
      transform: rotate(180deg);
    }

    &--next {
      right: -20px;
      padding-left: 10px;
    }
  }

  &-indicators {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    list-style: none;
    margin: 0;
    display: flex;
    padding: $spacingUnit * 2;
    justify-content: center;

    &--dot {
      margin: 0 6px;

      span {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: rgba(255,255,255, 0.3);
        transition: background-color $transition;
      }

      &Active {
        span {
          background-color: rgba(255,255,255, 1);
        }
      }
    }
  }
}


.carousel-cell {
  &.slider-slide {
    width: 100%;
    
  }

}

.main-carousel {

  .flickity-page-dots .dot {
    width: 30px;
    height: 30px;
    position: relative;
    opacity: 1;
    background: transparent;
    margin-right: 0;
    margin-left: 0;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: rgba(255,255,255,0.3);
      transition: background $transition ease;
    }
  }

  .flickity-page-dots .dot.is-selected {

    &::after {
      background: rgba(255,255,255,1);
    }
    
  }

  @media(max-width: 599px) {
    .flickity-button {
      display: none;
    }
  }

  &.hero-slideshow {
    .flickity-page-dots {
      bottom: 20px;
    }
  }

  &.download-slideshow {
    .carousel-cell {
      width: 100%;
      opacity: 0;
      transition: opacity $transition ease;

      &.is-selected {
        opacity: 1;
      }
    }

    .flickity-page-dots {
      bottom: -85px;
    }

    .flickity-page-dots .dot {

      &::after {
        background: $color7;
      }
    }

    .flickity-page-dots .dot.is-selected {
      &::after {
        background: $color3;
      }
    }
  }

  &.webcam-slideshow {
    width: calc(100% + 20px);
    margin-left: -10px;

    .flickity-button {
      display: block;
    }

    @include ipad {
      width: calc(100% + 30px);
      margin-left: -15px;
    }

    .flickity-button {
      background: transparent;

      .flickity-button-icon {
        fill: #fff;
      }

      &:hover {
        background: transparent;
      }
    }

    .carousel-cell {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;

      @include ipad {
        width: 50%;
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  &.plot-slideshow {
    .flickity-button {
      background: transparent;
      transition: opacity $transition ease;
        
      &:disabled {
        opacity: 0;
      }

      .flickity-button-icon {
        fill: #fff;
      }

      &:hover {
        background: transparent;
      }
    }
  }

  &.blog-slideshow {
    .flickity-button {
      background: transparent;

      .flickity-button-icon {
        fill: $color3;
      }

      &:hover {
        background: transparent;
      }
    }

    @include ipad {
      &.flickity-prev-next-button.next {
        right: -20px;
      }
  
      &.flickity-prev-next-button.previous {
        left: -20px;
      }
    }


    .flickity-page-dots {
      position: relative;
      bottom: -15px;

      @include ipad {
        bottom: 5px;
      }
    }

    .flickity-page-dots .dot {

      &::after {
        background: $color7;
      }
    }
    .flickity-page-dots .dot.is-selected {

      &::after {
        background: $color3;
      }
    }
  }
}

