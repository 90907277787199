.nav {
    height: 100%;
    background-color: #fff;
    font-family: $serif;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &-item {
        font-weight: 700;
        font-size: 18px;
        margin-top: 25px;
        margin-bottom: 25px;

        a {
            text-decoration: none;
            color: $color8;
            transition: color $transition ease;
            display: flex;
            line-height: 22px;   
        }

        svg {
            width: 22px;
            height: 22px;
            flex-shrink: 0;
            margin-right: 8px;

            .nav-icon--path {
                transition: fill $transition ease;
                fill: $color8;
            }
        }

        &--language {
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(0,0,0,0.15);
            padding-bottom: 23px;
            
            .input-field {
                height: 26px;
                padding: 0;
                font-weight: 700;
                font-size: 18px;
                color: $color8;
                cursor: pointer;
                width: auto;
                padding-right: 10px;
            }

            svg {
                margin-right: 4px;
            }
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--active,
        &:hover {
            a {
                color: $color1;

                &:focus {
                    outline: none;
                }

                svg {
                    .nav-icon--path {
                        fill: $color1;
                    }
                }
            }
        }
    }

    @include desktop {
        ul {
            padding-top: 70px;
        }
    }
}