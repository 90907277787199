*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    outline: none;
}

html{
    scroll-behavior: smooth;
}

/**
 * Disable smooth scrolling when users have prefers-reduced-motion enabled
 */
 @media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

body {
    width: 100%;
    font-size: 14px;
    justify-content: center;
    background-color: $color1;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: $sansSerif;
    color: $color10;
}

.container {
    &-main,
    &-cards {
        width: 100%;
    }

    &-main,
    &-card {
        display: flex;
    }

    &-main {
        max-width: $maxContentWidth;
        margin: 0 auto;
        background-color: $color2;
        overflow-x: hidden;
        transition: border-radius $transition ease;

        &--login {
            background-color: #fff;
            max-width: 540px;
        }

        @media(min-width: 1170px) {
            border-radius: $borderRadius;
        }
    }

    

    &-cards {
        padding: $spacingUnit;
    }

    &-card {
        width: calc(100% + #{$spacingUnit});
        margin-left: calc((#{$spacingUnit} / 2) * -1);
        margin-top: $spacingUnit;
        margin-bottom: $spacingUnit;
        flex-wrap: wrap;
    }

    &-nav {
        padding: $spacingUnit;  
        background-color: #fff;  
    }

    @media(max-width: $breakpointDesktop - 1) {
        &-cards {
            transform: translateX(0);
            transition: transform $transition ease, opacity $transition ease;
        }

        &-nav {
            top: 73px;
            width: 100%;
            transform: translateX(-100%);
            opacity: 0;
            transition: transform $transition ease, opacity $transition $transition;
            height: calc(100% - 73px);
            position: fixed;
        }
    }

    @include tablet {
        &-card {
            flex-wrap: nowrap;
        }
    }

    @include desktop {

        &-nav,
        &-cards {
            padding: $spacingUnit * 2;
        }

        &-cards {
            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
            max-width: 885px;

        }

        &-nav {
            flex-basis: 285px;
            border-top-left-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }

        &-card {
            width: calc(100% + (#{$spacingUnit} * 2));
            margin-left: calc(#{$spacingUnit} * -1);
            margin-top: $spacingUnit * 2;
            margin-bottom: $spacingUnit * 2;
        }
    }
}

.video__embedcontainer {
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%;

    iframe,
    object,
    embed {
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%;
    }
}


@media (max-width: $breakpointDesktop - 1) {
    .menu-open {
        .container-nav {
            transform: translateX(0);
            opacity: 1;
        }
    
        .container-cards {
            transform: translateX(100%);
            opacity: 0;
        }
    }
}

.bgcolor {
    &-one {
        background-color: $color1!important;
    }

    &-two {
        background-color: $color2!important;
    }

    &-three {
        background-color: $color3!important; 
    }

    &-four {
        background-color: $color4!important; 
    }

    &-five {
        background-color: $color5!important; 
    }

    &-eleven {
        background-color: $color11!important;
    }
}