.columns {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &.columns-half {
        width: calc(100% + (#{$spacingUnit} * 2));
        transform: translateX(-$spacingUnit);

        .column {
            padding: 0 $spacingUnit;
            margin-bottom: $spacingUnit;
            flex-basis: 100%;
            width: 100%;
            flex-shrink: 0;
            flex-grow: 1;

            &:last-child {
                margin-bottom: 0;
            }

            @include tablet {
                flex-basis: 50%;
                width: 50%;
                margin-bottom: 0;
            }
        }
    }
}