.card {
    padding: $spacingUnit * 2;
    border-radius: $borderRadius;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 100%;
    max-width: calc(100% - #{$spacingUnit});
    margin: 0 $spacingUnit / 2;
    margin-bottom: $spacingUnit;
    box-shadow: $boxShadow;
    transition: box-shadow $transition ease;
    background-color: #fff;
    font-size: $button;
    font-weight: 500;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.4);
    }

    &.bgcolor-four,
    &.bgcolor-four .card-title,
    &.bgcolor-five,
    &.bgcolor-five .card-title {
        color: #fff
    }

    &-group {
        &--three {

            @media(max-width: 767px) {
                flex-wrap: wrap;

                .card {
                    &:nth-child(1) {
                        width: 100%;
                        flex-basis: 100%;
                        margin-bottom: 15px;
                    }
                }

            }
        }
    }

    &-login {
        width: 100%;
        padding: $spacingUnit * 2;

        h1 {
            font-family: $sansSerif;
            text-align: center;
        }

        .input-field {
            border-radius: $borderRadius;
            background-color: $color9;
            width: 100%;
            flex-grow: 1;
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &--content {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &--or {
            text-align: center;
            font-weight: 500;
            color: $color6;
        }

        &--forgot {
            padding-top: 20px;
            text-align: center;

            p {
                margin: 0;

                &:nth-child(1) {
                    margin-bottom: $spacingUnit;
                }
            }

            a {
                color: $color5;
            }
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: center;
    }

    &-title {
        font-size: $cardTitle;
    }

    &-title,
    &-intro {
        margin-top: 0;
        align-self: center;
        max-width: 540px;
        margin-bottom: $spacingUnit;
    }

    &-split {
        &--container {
            margin-top: $spacingUnit;

            @include ipad {
                display: flex;
                text-align: left;
            }

            &Img {
                margin-bottom: $spacingUnit * 2;

                @include aspect-ratio(16,9);
            }

            .ratio-content {
                width: 100%;
                height: 100%;
                // height: auto;
                object-fit: cover;
                object-position: center;
            }
        }

        &--column {

            @include ipad {
                width: 50%;
                flex-basis: 50%;

                &:nth-child(2) {
                    padding-left: $spacingUnit * 4;
                }
            }
        }
    }

    // &-scroller {
    //     display: flex;
    //     overflow-x: scroll;
    //     scroll-snap-type: x mandatory;

    //     @include hideScrollBars();

    //     &--item {
    //         flex-basis: 100%;
    //         flex-shrink: 0;
    //         scroll-snap-align: start;
    //         // opacity: 0;
    //         // transition: opacity 0.75s;

    //         // &.in-view {
    //         //     opacity: 1;
    //         // }
    //     }
    // }

    &-signup {
        text-align: center;
        box-sizing: border-box;
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
        background-color: $color9;
        padding: $spacingUnit * 2;

        >p {
            margin-top: 0;
            margin-bottom: $spacingUnit;
        }

        form {
            display: flex;
            flex-wrap: wrap;
            max-width: 450px;
            margin: 0 auto;
        }

        input,
        button {
            height: 40px;
            flex-basis: 100%;
            border: none;
        }

        input {
            padding: 0 $spacingUnit;
            text-align: center;
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
        }

        button {
            padding: 0 $spacingUnit;
            background-color: $color3;
            color: #fff;
            border-bottom-left-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
            transition: background-color $transition ease;

            &:hover {
                cursor: pointer;
            }

            &:hover,
            &:active {
                background-color: darken($color3, 10%);
            }
        }
    }

    &-weather {
        flex-basis: auto;
        flex-shrink: 1;
        flex-grow: 0;

        &--reading {
            height: 34px;
            margin-bottom: 40px;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 500;

            @media(max-width: 767px) {
                font-size: 14px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            img {
                margin-right: 10px;
                width: 34px;
                height: 25px;
                // background-color: #000;

                @media(max-width: 374px) {
                    margin-right: 5px;
                    width: 24px;
                    height: 21px;
                }
            }
        }
    }

    &-live {
        display: flex;
        // line-height: 27px;
        font-weight: 500;
        // flex-direction: column;

        // @media(max-width: 767px) {
            // display: block;
            line-height: 1.1;
            font-size: 14px;
            flex-direction: column;
        // }

        // from{
        //     display: inline-block;
        //     @media(max-width: 767px) {
        //         display: block;
        //     }
        // }

        span {
            position: relative;
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 6px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 12px;
                height: 12px;
                border: 1px solid #fff;
                border-radius: 50%;
            }

            &::after {
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                background-color: #fff;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                animation: livePulse 2.5s infinite;
            }
        }

        &--indicator {
            display: flex;
            align-items: center;
            height: 27px;
            line-height: 27px;
            background-color: rgb(203,60,60);
            border-radius: $borderRadius / 2;
            text-transform: uppercase;
            padding: 0 10px;
            margin-right: 6px;
            margin-bottom: 5px;
            align-self: flex-start;

            @media(max-width: 767px) {
                display: inline-block;
            }
        }
    }

    &-tour {
        &--img {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 80px;
        }
    }

    &-plot {
        &--item {
            position: relative;
            flex-grow: 1;
            flex-shrink: 0;
            background-color: #fff;
            border-radius: $borderRadius;
            overflow: hidden;
            box-shadow: $boxShadow;
            margin-left: $spacingUnit;
            margin-right: $spacingUnit;
            margin-bottom: $spacingUnit * 2;
            width: calc(75% - 30px);
            opacity: 0.25;
            filter: blur(1.5px);
            transition: opacity $transition ease, filter $transition ease;

            &.is-selected {
                opacity: 1;
                filter: blur(0);
            }


            @include ipad {
                width: 50%;
                max-width: 235px;
            }
        }

        &--active {
            text-decoration: none;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1;
            height: 27px;
            font-size: 14px;
            line-height: 27px;
            padding: 0 10px;
            border-radius: $borderRadius / 2;
            font-family: $sansSerif;
            border: none;
            background-color: rgba(0,0,0,0.3);
            color: #fff;
            transition: background-color $transition ease;

            &:hover {
                background-color: $color3;
                cursor: pointer;
            }

            &Set {
                background-color: $color3;

                &:hover {
                    cursor: auto;
                    background-color: darken($color3, 10%);
                }
            }
        }

        &--cta {
            flex-direction: row;

            .btn {
                margin-left: 2px;
                margin-right: 2px;
            }
        }

        &--contImg {
            @include aspect-ratio(5,3);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &--content {
            color: $color10;
            padding: $spacingUnit;
        }

        &--title,
        &--location,
        &--number {
            margin-top: 0;
            margin-bottom: $spacingUnit;
            font-size: 14px;
            font-family: $sansSerif;
        }

        &--location {
            margin-bottom: 0;
        }

        &--location,
        &--number {
            font-weight: 500;
            color: $color8;
        }

        &--title,
        &--number span {
            font-weight: 700;
        }


    }

    &-plotsize {
        &--total {
            position: relative;
            width: 130px;
            background-position: center;
            background-repeat: no-repeat;
            margin: 0 auto $spacingUnit * 2 auto;

            >p {
                display: inline-block;
                background-color: #fff;
                padding: 5px;
                margin: 0;
                font-weight: 600;

            }
        }
    }

    &-distance {
        &--icons {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            max-width: 215px;
            margin: 0 auto 50px auto;
        }
        &--profile {
            width: 65px;
            height: 65px;
            background-color: #000;
            border-radius: 50%;
            flex-shrink: 0;

            img {
                width: 100%;
                height: auto;
            }
        }

        &--separator {
            width: 100%;
            height: 5px;
            border-top: 3px dotted $color6;
            flex-grow: 1;
            flex-shrink: 1;
            margin: 0 5px 28px;
        }

        &--plot {
            flex-shrink: 0;
            svg {
                vertical-align: bottom;
                width: 65px;
            }
        }
    }

    &-reserve {

        &--chart {
            position: relative;
        }

        &--number {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            margin: 0 auto;
            left: 0;
            right: 0;
            font-family: $sansSerif;
            font-weight: 300;
            color: $color8;
            font-size: 30px;
        }

        &--list {
            ol {
                list-style: none;
                counter-reset: items;
                padding: 0;
                margin-bottom: $spacingUnit * 2;

                li {
                    counter-increment: items;
                    font-weight: 500;

                    &:before {
                        content: "0" counter(items)". ";
                        font-weight: 700;
                    }

                    &:nth-child(n+10):before {
                        content: counter(items)". ";
                    }

                    &.inactivereserve{
                        color:#888;
                    }
                    &.inactivereserve.first{
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    &-achievement {
        &--grid {
            display: flex;
            position: relative;
            flex-wrap: wrap;
            margin-top: $spacingUnit;
        }

        &--item {
            flex-basis: 50%;
            flex-grow: 0;
            flex-shrink: 0;
            margin-bottom: $spacingUnit * 2;
            padding: 0 10px;
            transition: 0.35s ease;

            svg {
                pointer-events: none;
                transition: transform $transition ease;
            }

            &.active {
                .badge {
                    transition: 0.35s ease;
                }

                &:hover {
                    .badge {
                        transform: scale(1);
                    }
                }

                &.grow {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    .badge {
                        max-width: 240px;
                    }
                }
            }

            @include ipad {
                flex-basis: calc(100% / 3);
            }

            &:hover {
                cursor: pointer;

                svg {
                    transform: scale(1.1);
                }
            }
        }

        &--title,
        &--description {
            font-family: $sansSerif;
        }

        &--title {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0;

            @include ipad {
                font-size: 20px;
            }
        }

        &--description {
            font-size: 12px;
            margin-top: 0;
            font-weight: 400;

            @include ipad {
                font-size: 16px;
            }
        }
    }

    .btn {
        margin-top: auto;
        align-self: center;
    }

    @include tablet {
        margin-bottom: 0;
        flex-basis: calc(100% / 3);
        min-width: calc((100% / 3) - #{$spacingUnit} * 2);

        &-signup {
            form {
                flex-wrap: nowrap;
            }

            input {
                border-top-left-radius: $borderRadius;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: $borderRadius;
                text-align: left;
            }

            button {
                border-top-left-radius: 0;
                border-top-right-radius: $borderRadius;
                border-bottom-right-radius: $borderRadius;
                border-bottom-left-radius: 0;
                flex-shrink: 0;
                flex-basis: auto;
            }
        }
    }

    @include desktop {
        margin: 0 $spacingUnit;
    }

    // video/iframe embed card
    &-embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

// Individual Card Styles

#card {
    &HeroSlideshow {
        padding: 0;
        overflow: hidden;

        .slide {
            padding: 0;
            max-width: 100%;
            margin: 0;
        }

        .bgcolor-three {
            .btn {
                background-color: rgb(128, 118, 102);
                border-color: rgb(128, 118, 102);

                &:hover {
                    background-color: darken(rgb(128, 118, 102), 10%);
                    border-color: darken(rgb(128, 118, 102), 10%);
                }
            }
        }

        .bgcolor-eleven {
            .btn {
                background-color: rgb(92,148,178);
                border-color: rgb(92,148,178);

                &:hover {
                    background-color: darken(rgb(92,148,178), 10%);
                    border-color: darken(rgb(92,148,178), 10%);
                }
            }
        }

        .card-split--container {
            display: flex;
            flex-direction: column-reverse;
            margin-top: 0;
            height: 100%;

            @include tablet {
                flex-direction: row;
            }

            &Img {
                margin-bottom: 0;
                height: 100%;

                @include aspect-ratio(1,1);

                img {
                    height: 100%;
                    object-position: bottom;
                }
            }
        }

        .card-split--column {

            @include tablet {
                .card-title,
                .btn {
                    align-self: flex-start;
                }
            }
            &:nth-child(1) {
                padding: $spacingUnit * 2;
                padding-bottom: 60px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-basis: calc((100% / 3) * 2);

                @include tablet {
                    padding: $spacingUnit * 4;
                    text-align: left;
                }

                .btn {
                    margin-top: 0;
                }
            }

            @include tablet {
                &:nth-child(2) {
                    flex-basis: calc(100% / 3);
                    flex-shrink: 0;
                    padding-left: 0;
                }
            }
        }
    }

    &Webcam {
        position: relative;
        color: #fff;
        display: flex;
        overflow: hidden;
        background-size: 170%;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px;

        @include ipad {
            padding: 30px;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to bottom,#38a9e5 20%,rgba(255,255,255,0) 100%);
            transform: rotate(-15deg) scale(1.5) translateY(-70px);
        }

        .card-content {
            text-align: left;
            flex-grow: 1;
            flex-shrink: 1;
            z-index: 1;
        }

        .card-weather {
            z-index: 1;
        }

        .btn {
            align-self: flex-start;
        }
    }

    &Tour {
        overflow: hidden;
        position: relative;
        background-color: rgb(86,184,246);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px;

        @include ipad {
            padding: 30px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(86,184,246, 0.5);
        }

        .card {
            &-content {
                display: flex;
                text-align: left;
                color: #fff;
                position: relative;
                z-index: 1;

                .btn {
                    align-self: flex-end;
                }

                >p {
                    max-width: 170px;
                    margin-top: 0;
                }
            }
            &-title {
                max-width: 170px;
                align-self: flex-start;
                color: #fff;
            }
        }
    }

    &Plots {
        padding-left: 0;
        padding-right: 0;
        width: calc(100% - #{$spacingUnit});

        .card-title,
        .card-intro {
            padding-left: 20px;
            padding-right: 20px;

            @include ipad {
                padding-left: 0;
                padding-right: 0;
            }
        }

        hr {
            align-self: center;
            margin-top: 30px;
            margin-bottom: 20px;
            max-width: 540px;
            width: calc(100% - 80px);
            border: none;
            border-top: 1px solid rgba(255,255,255,0.25);
            padding-left: 20px;
            padding-right: 20px;

            @include ipad {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .card-content {
            >h3 {
                font-size: 24px;
                margin-bottom: 20px;
            }
        }

        .card-scroller {
            width: 100%;
            max-width: 550px;
            margin: 0 auto;
            padding: $spacingUnit 0 0 0;
        }
    }

    &PlotSize {
        svg {
            width: 130px;
            margin: 0 auto 50px auto;
        }
    }

    &Distance {
        .card-intro {
            span {
                font-weight: 600;
            }
        }
    }

    &Updates,
    &Blog {
        .card-split--column {
            h2,
            h3 {
                font-family: $sansSerif;
            }

            h2 {
                font-weight: 600;
                font-size: 24px;
                margin: 0 0 $spacingUnit 0;
            }

            h3 {
                font-weight: 700;
                font-size: 14px;
                text-transform: uppercase;
                color: $color3;
                margin: 0 0 $spacingUnit 0;
            }

            p {
                font-size: 16px;
                font-weight: 400;

                @include ipad {
                    margin: 0 0 $spacingUnit 0;
                }
            }
        }
    }

    &Handbook {
        .card-split--container {
            margin-top: 0;
        }

        .card-split--column {
            &:nth-child(1) {
                margin-bottom: $spacingUnit * 2;

                @include ipad {
                    margin-bottom: 0;
                }
            }

            img {
                width: 100%;
                max-width: 400px;
                height: auto;
            }

            .btn {
                margin-top: $spacingUnit;
            }
        }
    }

    &Visits {
        .visits {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: $spacingUnit * 2;
        
            @include ipad {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: stretch;
                justify-content: center;
            }
        
            &-item {
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                background-color: #fff;
                padding: $spacingUnit;
                border-radius: $borderRadius;
                box-shadow: $boxShadow;
                gap: $spacingUnit;
                max-width: 366px;
        
                @include ipad {
                    width: calc(50% - #{$spacingUnit});
                }
        
                @include desktop {
                    width: 100%;
                    max-width: 100%;
                    flex-wrap: nowrap;
                    text-align: left;
                    gap: $spacingUnit * 2;
                }
        
                &--visited {
                    .visits-item--icon {
                        svg {
                            fill: $color4;
                        }
                    }
                }
        
                &--area {
                    width: 33.333%;
                    flex-shrink: 1;
                    flex-grow: 0;
                    display: flex;
                    gap: $spacingUnit;
                    align-items: center;
                    flex-direction: column;
        
                    @include desktop {
                        max-width: 220px;
                        flex-direction: row;
                    }
        
                    &Title {
                        width: 100%;
        
                        @include desktop {
                            max-width: 290px;
                            flex-grow: 1;
                            width: 50%;
                        }
        
                        .btn {
                            background-color: $color3;
                            border-color: $color3;
                            color: #fff;
                            transition: background-color $transition ease, border-color $transition ease;
        
                            &:hover {
                                cursor: pointer;
                            }
        
                            &:hover,
                            &:active {
                                border-color: darken($color3, 10%);
                                background-color: darken($color3, 10%);
                            }
                        }
                    }
        
                    &Logged {
                        width: calc(50% - (#{$spacingUnit} / 2));
        
                        @include desktop {
                            width: calc(33.33% - (#{$spacingUnit} / 2));
                        }
        
                        span {
                            font-size: 32px;
                            font-weight: 700;
                            display: block;
                        }
                    }
        
                    &Date {
                        width: calc(50% - (#{$spacingUnit} / 2));
        
                        @include desktop {
                            width: calc(33.33% - (#{$spacingUnit} / 2));
                        }
        
                        span {
                            font-weight: 700;
                        }
                    }
                }
        
                &--media {
                    width: 92px;
                    height: 92px;
                    border-radius: 50%;
                    overflow: hidden;
                    flex-shrink: 0;
                    flex-grow: 0;
        
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
        
                &--icon {
                    width: 68px;
                    height: 68px;
        
                    svg {
                        fill: #ddd;
                    }
                }
        
                &--content {
                    color: $color10;
        
                    >* {
                        &:first-child {
                            margin-top: 0;
                        }
        
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    &Blog {
        // @include ipad {
        //     padding-left: 50px;
        //     padding-right: 50px;
        //     padding-bottom: 20px;
        // }
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        .slider-indicators {
            position: relative;
            padding: 0;

            &--dot {
                span {
                    background-color: $color7;
                }

                &Active {
                    span {
                        background-color: $color3;
                    }
                }
            }
        }
        .card-content {
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: $spacingUnit;
            height: auto;

            @media(min-width: 768px) {
                padding-left: 50px;
                padding-right: 50px;
            }

            .card-split--column:nth-child(2) {
                padding-left: 0;
                padding-bottom: 20px;

                @media(min-width: 768px) {
                    padding-left: 20px;
                }
            }
        }
        .flickity-prev-next-button.previous{
            left: -47px;
        }
        .flickity-prev-next-button.next {
            right: -47px;
        }
    }

    &Updates {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        .card-content {
            padding-left: $spacingUnit * 2;
            padding-right: $spacingUnit * 2;
            padding-bottom: $spacingUnit * 2;
            height: auto;
        }
    }

    &WelcomeVideo{

        // Set to just minimal spacing to fill edge-to-edge(ish) video
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        // However text content still needs padded in as usual
        .card-intro,
        .card-title {
            padding-left: 10px;
            padding-right: 10px;
        }

        .card-content {
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
        }
    }
}

@keyframes livePulse {
    0%
    {
        opacity: 0.25;
        transform: scale( .5 );
    }
    50%
    {
        opacity: 1;
        transform: scale( 1 );
    }
    100%
    {
        opacity: 0.25;
        transform: scale( .5 );
    }
}