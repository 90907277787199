@import 'base/reset', 
        'utils/variables', 
        'base/typography', 
        'utils/mixins', 
        'themes/theme', 
        'components/profile',
        'components/overlay', 
        'components/slideshow', 
        'components/orders', 
        'components/help', 
        'components/card', 
        'components/button', 
        'components/badges', 
        'components/input', 
        'components/details', 
        'components/indicator', 
        'layout/header', 
        'layout/columns', 
        'layout/nav', 
        'layout/footer';


.hidden{
    display: none;
}

.passwordstrength{
    width: 100%;
    margin-bottom: 0;
    &.weak{
        color: #FF0000;
    }
    &.good{
        color: #FFD200;
    }
    &.strong{
        color: #67BB25;
    }
    &.vstrong{
        color: green;
    }
    & strong{
        color: #3a3a3a
    }
}

p.alert{
    padding: 8px 9px;
    font-size: 16px;

    &--err{
        background-color: #ffc9c9;
    }

    &--success{
        background-color: #9eec9e;
    }

    a {
        text-decoration: none;
        color: $color5;
        transition: color $transition ease;

        &:hover {
            color: lighten($color5, 10%);
        }
    }
}