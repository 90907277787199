.badge {
    width: 80%;
    max-width: 100px;

    &-inner,
    &-plate {
        fill: #b7b7b7;
    }

    &-border {
        fill: #b7b7b7;
    }

    &-content {
        fill: #fff;

        &--darker {
            fill: rgb(157,157,157);
        }
    }

    &-unlocked {
        .badge-plate {
            fill: rgb(222,188,115);
        }

        .badge-content--darker {
            fill: darken(rgb(222,188,115), 15%);
        }

        .badge-border {
            fill: #fff;
        }
    }
}