.indicators {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin: $spacingUnit * 2 0;
    
    &-dot {
        margin: 0 6px;
        span {
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $color7;
            transition: background-color $transition ease;
        }

        &:hover {
            cursor: pointer;

            span {
                background-color: darken($color7, 10%);
            }
        }

        &--active {
            span {
                background-color: $color3;
            }

            &:hover {
                span {
                    background-color: darken($color3, 10%);
                }
            }
        }
    }
}