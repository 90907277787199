.profile {
    display: flex;

    &-welcome {
        font-size: 20px;
        margin: 0;
        font-weight: 700;

        a {
            color: $color10;
            text-decoration: none;
            transition: color $transition ease;

            &:hover {
                color: lighten($color10, 10%);
            }
        }
    }

    &-time {
        margin: 0;

        a {
            color: $color6;
            text-decoration: none;
            transition: color $transition ease;

            &:hover {
                color: darken($color6, 10%);
            }
        }
    }

    &-time,
    &-plot {
        font-size: 16px;
        color: $color6;
        font-weight: 600;
    }

    &-plot {
        display: flex;
        align-items: center;

        &--number {
            margin: 0 6px 0 0;

            a {
                color: $color6;
                text-decoration: none;
                transition: color $transition ease;

                &:hover {
                    color: darken($color6, 10%);
                }
            }
        }
    }

    .btn {
        border: none;
        background-color: $color7;
        color: rgba(0,0,0,0.5);
        font-weight: 600;
        border-radius: $borderRadius / 2;
        height: 27px;
        line-height: 27px;
        padding: 0 10px;
        display: flex;

        svg {
            width: 12px;
            flex-shrink: 0;
            height: 27px;
            margin-left: 6px;
        }

        &:focus,
        &:hover {
            background-color: darken($color7, 10%);
        }

    }

    &-img {
        overflow: hidden;

        img {
            display: none;
            transition: transform $transition ease;
        }

        &:hover {
            cursor: pointer;
            
            img {
                transform: scale(1.1);
            }
        }
    }

    @include desktop {
        &-img {
            // background-color: #000;
            border-radius: 50%;
            width: 65px;
            height: 65px;
            margin-right: $spacingUnit;

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }
}