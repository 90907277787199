// Helper for commonly used font declarations
@mixin text($size: null, $lineHeight: null, $weight: null) {
    @if $size != null {
        font-size: $size;
    }

    @if $lineHeight != null {
        line-height: $lineHeight;
    }

    @if $weight != null {
        font-weight: $weight;
    }
}

@mixin placeholderColor($placeholderColor) {
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $placeholderColor;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $placeholderColor;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: $placeholderColor;
    }
}

@mixin hideScrollBars {
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    
    > .ratio-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

// Device media queries
@mixin iphone5-only {
    @media (max-width: $breakpointIphone5) { @content; }
}

@mixin tablet {
    @media (min-width: $breakpointTablet) { @content; }
}

@mixin ipad {
    @media (min-width: $breakpointIpad) { @content; }
}

@mixin desktop {
    @media (min-width: $breakpointDesktop) { @content; }
}